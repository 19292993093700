import { storyblokEditable } from '@storyblok/js';
import { graphql } from 'gatsby';
import PropTypes from 'prop-types';
import React from 'react';

import DynamicComponent from '../components/dynamic-component';
import Seo from '../components/elements/seo';
import Layout from '../components/layout';
import useStoryblok from '../hooks/use-storyblok';

export default function HomePage({ data, location }) {
	const page = useStoryblok(data.story, location);
	const blok = page.content;

	return (
		<Layout>
			<Seo {...blok.meta} path={location.pathname} />
			<div {...storyblokEditable(blok)}>
				{blok.body &&
					blok.body.map((child) => (
						<DynamicComponent location={location} blok={child} key={child._uid} />
					))}
			</div>
		</Layout>
	);
}

HomePage.propTypes = {
	data: PropTypes.shape({
		story: PropTypes.shape({
			content: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
		}),
	}).isRequired,
	location: PropTypes.shape({
		pathname: PropTypes.string,
	}).isRequired,
};

export const query = graphql`
	query HomeQuery {
		story: storyblokEntry(full_slug: { eq: "corporate/index" }) {
			name
			content
			full_slug
			uuid
			id
		}
	}
`;
